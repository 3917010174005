class NavMain {
  constructor() {
    this.bindClickHandlers();
  }

  bindClickHandlers() {
    const burger = document.querySelector(".header-main__burger svg");
    const navMobile = document.querySelector(".nav-mobile");
    const navMobileCloseEle = document.querySelector(".header-mobile__close");
    const navOpenClassName = "nav-mobile--visible";
    const navMobileLabelEles = document.querySelectorAll(".nav-mobile__label");

    navMobileLabelEles.forEach(navMobileLabelEle => {
      navMobileLabelEle.addEventListener("click", () => {
        navMobileLabelEle.classList.toggle("nav-mobile__label--open");
        navMobileLabelEle.parentNode
          .querySelector(".nav-mobile__list--secondary")
          .classList.toggle("nav-mobile__list--secondary--open");
      });
    });

    burger.addEventListener("click", () => {
      navMobile.classList.add(navOpenClassName);
    });

    navMobileCloseEle.addEventListener("click", () => {
      navMobile.classList.remove(navOpenClassName);
    });
  }
}

document.addEventListener("turbolinks:load", () => {
  new NavMain();
});
