import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Heading = styled.h3`
  font-size: 2.4rem;
  margin-bottom: 20px;
`;

const LocationListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OnlineServicesWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.grey};
  padding-bottom: 40px;
`;

const LocationListItem = styled.div`
  margin-bottom: 20px;
  position: relative;
  flex-basis: 100%;

  @media screen and (min-width: ${props => props.theme.breakpointSmall}) {
    flex-basis: 40%;
    margin-bottom: 40px;
  }

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    flex-basis: 47%;
  }
`;

const LocationName = styled.h3`
  font-family: ${props => props.theme.fontFamilyPrimary};
  font-size: 1.9rem;
  margin-bottom: 10px;
`;

const LocationInfo = styled.span`
  display: block;
  color: ${props => props.theme.grey};
  font-size: 1.6rem;
  margin-bottom: 0;
  text-decoration: none;
  font-weight: 300;
  line-height: 2.5rem;
`;

const LocationLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const LinkText = styled.a`
  color: ${props => props.theme.green};
  text-decoration: none;
`;

class LocationList extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { mappedLocations } = this.props;
    return (
      <>
        {mappedLocations && (
          <>
            <Heading>Sexual health clinics</Heading>
            <LocationListContainer>
              {mappedLocations.map(location => {
                return (
                  <LocationListItem key={location.lng}>
                    <LocationName>
                      { location.link ? (
                        <LocationLink target="__blank" href={location.link} rel="nofollow noreferrer noopener">
                          {location.name}{" "}
                        </LocationLink>
                      ) : location.name }
                    </LocationName>
                    {location.telephone && (
                      <LocationInfo>
                        <LocationLink href={`tel:${location.telephone}`}>
                          {location.telephone}
                        </LocationLink>
                      </LocationInfo>
                    )}
                    {location.address && (
                      <LocationInfo>{location.address}</LocationInfo>
                    )}
                  </LocationListItem>
                );
              })}
            </LocationListContainer>
          </>
        )}
      </>
    );
  }
}
LocationList.propTypes = {
  mappedLocations: PropTypes.array
};

export default LocationList;
