import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const Marker = styled.span`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: block;
  ${({ passed, theme, color }) => passed
    ? `background-color: ${theme[color]};`
    : `border: 2px solid ${theme[color]};`
  }
`;

const Line = styled.span`
  border-top-width: 2px;
  border-top-style: ${props => props.passed ? 'solid' : 'dashed'};
  border-top-color: ${props => props.theme[props.color]};
  flex-grow: 1;
  margin: -2px 5px 0;
`;

const Progress = ({ currentProgress, milestones }) => {

  return (
    <Row>
      {[...Array(milestones)].map((_, index) => {
        const passed = currentProgress >= index;
        const color = passed ? 'salmon' : 'whiteOpaque';

        return (
          <React.Fragment key={`milestone-${index}`}>
            {index !== 0 && <Line color={color} passed={passed} />}
            <Marker color={color} passed={passed} />
          </React.Fragment>
        );
      })}
    </Row>
  );
};

Progress.propTypes = {
  currentProgress: PropTypes.number.isRequired,
  milestones: PropTypes.number
};

Progress.defaultProps = {
  milestones: 4
}

export default Progress;
