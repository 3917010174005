import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from 'styled-components';

import theme from '../theme';

import { OutreachContextProvider } from './lib/OutreachContext';
import OutreachContainer from './OutreachContainer';

function Outreach({ regions, csrfToken }) {
  return (
    <OutreachContextProvider>
      <ThemeProvider theme={theme}>
        <OutreachContainer regions={regions} csrfToken={csrfToken} />
      </ThemeProvider>
    </OutreachContextProvider>
  );
}

Outreach.propTypes = {
  csrfToken: PropTypes.string.isRequired
};

export default hot(module)(Outreach);
