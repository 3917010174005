import React from "react";
import styled from "styled-components";

const SessionContentsWrapper = styled.div`
  background-color: white;
  padding: 5px;
  margin-top: 5px;
`;

class SessionContents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { sessionData } = this.props;
    const renderSessionData = sessionData && Object.keys(sessionData).map((key,value) => (
      <p>
        <strong>{`${key}: `}</strong>
        {JSON.stringify(sessionData[key])}
      </p>
    ));

    return (
      <SessionContentsWrapper>
        {renderSessionData}
      </SessionContentsWrapper>
    )
  }
}

export default SessionContents;
