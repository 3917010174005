import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Spinner from "images/icons_journey/loading_spinner.svg";

const Input = styled.input`
  background-color: ${props => props.theme.white};
  padding: 12px;
  color: #000;
  border: 1px solid ${props => props.theme.white};
  font-family: ${props => props.theme.fontFamilyPrimary};
  font-size: 1.8rem;
  font-weight: 300;
  margin: 10px 0 5px 0;
  border-radius: 3px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ isLoading }) =>
    isLoading &&
    `background-image: url(${Spinner});
    background-repeat: no-repeat;
    background-position: center right;
  `}
`;

const Label = styled.label`
  margin-top: 10px;
  font-size: 1.6rem;
  font-weight: 300;
  color: ${props => props.theme.white};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function TextInput({
  className,
  value,
  onChange,
  onKeyDown,
  label,
  attributeName,
  formName,
  invalid,
  disabled,
  inputType,
  loading,
  autocomplete,
  placeholder,
  autoFocus
}) {
  return (
    <Wrapper>
      <Label htmlFor={`${formName}_${attributeName}`}>{label}</Label>
      <Input
        isLoading={loading}
        type={inputType}
        disabled={disabled}
        className={className}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={`${formName}[${attributeName}]`}
        id={`${formName}_${attributeName}`}
        invalid={invalid}
        autoComplete={autocomplete}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    </Wrapper>
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  attributeName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputType: PropTypes.string,
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool
};

TextInput.defaultProps = {
  inputType: 'text'
};

export default TextInput;
