import React from "react";

import Heading from "../../presentational_components/Heading";
import SubText from "../../presentational_components/SubText";
import LinkText from "../../presentational_components/LinkText";
import WithFadeIn from "../../hoc/WithFadeIn";

class ErrorMessage extends React.Component {
  render() {
    return (
      <>
        <Heading>Something went wrong</Heading>
        <SubText>
          Rest assured that if you&apos;re seeing this message we know about it
          and we are already doing all we can to find a fix. We suggest you
          check your browser is up to date and that you are using Chrome,
          Safari, Firefox or Edge.
        </SubText>
        <SubText>
          If you would like to get in touch, please email:{" "}
          <LinkText href={"mailto:sh24.info@nhs.net"} externalLink={true}>
            sh24.info@nhs.net
          </LinkText>
          .
        </SubText>
      </>
    );
  }
}

export default WithFadeIn(ErrorMessage);
