import Cookies from "js-cookie";

document.addEventListener("turbolinks:load", () => {
  const cookieBanner = document.getElementById("cookie-banner");
  const showCookieBanner = !Cookies.get("cookies_accepted") && cookieBanner;

  if (showCookieBanner) {
    const cookieAcceptBtn = document.getElementById("cookie-banner__btn");

    cookieBanner.classList.add("cookie-banner--visible");
    cookieAcceptBtn.addEventListener("click", event => {
      event.preventDefault();
      Cookies.set("cookies_accepted", true);
      cookieBanner.classList.remove("cookie-banner--visible");
    });
  }
});
