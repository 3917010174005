import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import reactiveImg from "./images/reactive.png";
import negativeImg from "./images/negative.png";
import failedImg from "./images/failed.png";

const Container = styled.div`
  background: #59599F;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
  border: ${props => props.selected && '1px solid #fff'};
`;

const OptionHeading = styled.label`
  color: ${props => props.selected || '#fff'};
  background: ${props => props.selected && '#fff'};
  padding: 10px;
  margin: -10px;
  font-size: 1.8rem;
  display: block;
  margin-bottom: 10px;
`;

const ResultImage = styled.img`
  max-width: 100%;
  max-height: 100px;
`;

function Option({ value, formData, setFormDataItem }) {
  const selected = formData.result && formData.result.value == value;

  const handleOnClick = () => setFormDataItem('result', value);

  const headings = {
    reactive: 'Reactive (2 dots)',
    negative: 'Negative (1 dot at the top)',
    failed: 'Invalid (no dots or 1 dot at the bottom)'
  };

  const resultImages = {
    reactive: reactiveImg,
    negative: negativeImg,
    failed: failedImg
  };

  return (
    <Container role="option" selected={selected} onClick={handleOnClick}>
      <OptionHeading selected={selected}>{headings[value]}</OptionHeading>
      <ResultImage src={resultImages[value]} />
    </Container>
  );
}

Option.propTypes = {
  value: PropTypes.string.isRequired,
  setFormDataItem: PropTypes.func.isRequired
};

export default Option;
