class scrollListener {
  constructor() {
    this.bindScrollHandler();
    this.headerMain = document.querySelector(".header-main");
  }

  bindScrollHandler() {
    let lastKnownScrollPosition = 0;
    let ticking = false;

    window.addEventListener("scroll", () => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.onScroll(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  }

  handleHeaderMainClass(scrollPosition) {
    if (scrollPosition) {
      this.headerMain.classList.add("header-main--solid");
    } else {
      this.headerMain.classList.remove("header-main--solid");
    }
  }

  onScroll(scrollPosition) {
    this.handleHeaderMainClass(scrollPosition);
  }
}

document.addEventListener("turbolinks:load", () => {
  new scrollListener();
});
