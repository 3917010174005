import React from 'react';

import Heading from "../presentational_components/Heading";
import SubText from "../presentational_components/SubText";
import Button from "../presentational_components/Button";

function Negative() {
  return (
    <>
      <Heading>Your result has been submitted</Heading>
      <SubText>If you think you are at risk of HIV, you should test every 3 months.</SubText>
      <SubText>Read more about when to test and preventing HIV.</SubText>
      <SubText>
        <Button href={Routes.static_pages_about_hiv_path()+"#preventing"} colour="purple">Preventing and testing for HIV</Button>
      </SubText>
    </>
  )
}

export default Negative;
