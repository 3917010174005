import styled from "styled-components";
import Tick from "images/icons_journey/tick.svg";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  border: 1px solid ${props => props.theme.white};
  background-color: transparent;
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  min-width: 25px;
  margin-right: 15px;
  transition: background-color 0.2s;

  &:checked {
    background-image: url(${Tick});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus {
    outline: none;
    background-color: ${props => props.theme.whiteOpaque};
  }
`;

export default Checkbox;
