import React from "react";
import { hot } from "react-hot-loader";
import { ThemeProvider } from "styled-components";

import Question from "./question_components/Question";
import theme from "../theme";

class OrderJourney extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Question {...this.props} />
      </ThemeProvider>
    );
  }
}

export default hot(module)(OrderJourney);
