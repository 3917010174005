import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AbstractQuestion from "../question_components/AbstractQuestion";
import StyledInput from "../../presentational_components/StyledInput";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";

const Option = styled.input.attrs({ type: "radio" })`
  width: 0;
  height: 0;
  -webkit-appearance: none;

  &:checked + label {
    color: ${props => props.theme.purple};
    background-color: ${props => props.theme.white};
  }

  &:focus + label {
    border: 1px solid ${props => props.theme.white};
  }
`;

const Label = styled.label`
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.whiteOpaque};
  font-size: 1.6rem;
  line-height: 2rem;
  min-height: 42px;
  padding: 10px 20px;
  margin-right: 10px;
  transition: color 0.2s, background 0.2s, border 0.2s;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 1px solid ${props => props.theme.white};
  }

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    margin-bottom: 15px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    flex-direction: row;
  }
`;

const OptionWrapper = styled.div`
  display: contents;
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    ${props => props.columns && 'display: grid; flex: 1; margin-right: 40px;'}
  }
`;

class MultipleChoice extends React.Component {
  constructor(props) {
    super(props);
    const { options, value, otherValue } = this.props;

    this.state = {
      options: options.map(option => {
        return { ...option, selected: option.value === value ? true : false };
      }),
      otherValue: otherValue || ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOtherValueChange = this.handleOtherValueChange.bind(this);
  }

  handleChange(e) {
    const { options } = this.state;
    const selectedValue = e.target.value;

    this.setState({
      options: options.map(option => {
        return {
          ...option,
          selected: option.value === selectedValue ? true : false
        };
      })
    });
  }

  handleOtherValueChange(e) {
    this.setState({ otherValue: e.target.value });
  }

  renderOtherQuestion() {
    const { otherQuestionText, otherError, formName, attributeName } = this.props;
    const { options } = this.state;

    if (options.find(e => e.value == 'other' && e.selected)) {
      return (<>
        <StyledInput
          inputType="text"
          value={this.state.otherValue}
          onChange={this.handleOtherValueChange}
          label={otherQuestionText}
          formName={formName}
          attributeName={`${attributeName}_other`}
        />
        {otherError && <ErrorFeedback message={otherError} />}
      </>)
    }
  }

  canProgress() {
    const { otherRequired } = this.props;
    const { options, otherValue } = this.state;

    const selected = options.find(option => option.selected);

    if (!selected) return false;
    if (selected.value == 'other' && otherRequired && !otherValue) return false;

    return true;
  }

  isLastStep() {
    const { isLastStep, isLastStepIfValue } = this.props;
    const { options } = this.state;
    const selected = options.find(option => option.selected);

    if (isLastStep) return true;

    if (isLastStepIfValue && selected && selected.value == isLastStepIfValue) return true;

    return false;
  }

  render() {
    const { attributeName, formName } = this.props;
    const { options } = this.state;

    return (
      <AbstractQuestion
        {...this.props}
        isLastStep={this.isLastStep()}
        handleSubmit={this.props.handleSubmit}
        canProgress={this.canProgress()}
      >
        {this.props.children}
        <OptionsWrapper>
          {options.map(option => {
            return (
              <OptionWrapper columns={this.props.columns} key={option.value}>
                <Option
                  checked={option.selected}
                  onChange={this.handleChange}
                  id={`${formName}_${attributeName}_${option.value}`}
                  name={`${formName}[${attributeName}]`}
                  value={option.value}
                  key={option.label}
                />
                <Label htmlFor={`${formName}_${attributeName}_${option.value}`}>
                  {option.label}
                </Label>
              </OptionWrapper>
            );
          })}
          <input type="hidden" name="_ie10_11_fix" />
        </OptionsWrapper>

        {this.renderOtherQuestion()}
      </AbstractQuestion>
    );
  }
}

MultipleChoice.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  otherRequired: PropTypes.bool.isRequired,
  isLastStepIfValue: PropTypes.string,
  columns: PropTypes.bool
};

MultipleChoice.defaultProps = {
  otherRequired: false,
  columns: false
};

export default MultipleChoice;
