import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Progress from '../../presentational_components/Progress';
import DateOfBirth from "./DateOfBirth";
import SingleTextInput from "./SingleTextInput";
import Kickout from "./Kickout";
import TermsAndConditions from "./TermsAndConditions";
import Select from "./Select";
import ErrorMessage from "./ErrorMessage";
import MultipleChoice from "./MultipleChoice";
import RapidOrLabTestChoice from "./RapidOrLabTestChoice";
import RapidOrLabTestInfo from "./RapidOrLabTestInfo"
import InformationOnly from "./InformationOnly";
import Name from "./Name";
import Address from "./Address";
import Result from "./Result";
import Loading from "./Loading";
import callApi from "../../api/callApi";
import scrollTo from "../../../scrollTo";
import SessionContents from "../SessionContents";
import OutreachMobileNumber from "./OutreachMobileNumber";
import OutreachResult from "./OutreachResult";
import SubText from "../../presentational_components/SubText";
import Confirm from "../../presentational_components/Confirm";

const transitionOutDuration = 250;
const artificialLoadDelay = 750;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.center ? 'center' : 'flex-start'};
  min-height: 400px;

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    min-height: 270px;
  }
`;

const MainContentWrapper = styled.div`
  transition: opacity ${transitionOutDuration}ms;
  opacity: ${props => (props.transitionOut ? 0 : 1)};
`;

const EndLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10em;
`;

const FadedSubText = styled(SubText)`
  opacity: .7;
  margin: 0 auto;
`;

const components = {
  Postcode: SingleTextInput,
  DateOfBirth,
  Kickout,
  TermsAndConditions,
  Select,
  MultipleChoice,
  RapidOrLabTestChoice,
  RapidOrLabTestInfo,
  InformationOnly,
  Name,
  Email: SingleTextInput,
  MobileNumber: SingleTextInput,
  Address,
  MobileNumberVerification: SingleTextInput,
  Result,
  OutreachMobileNumber,
  OutreachName: SingleTextInput,
  OutreachResult
};

const questionsWithValidation = ["Postcode"];

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      transitionOut: false,
      currentQuestion: props.questionData,
      error: false,
      isLoading: false,
      showLoadingSpinner: false,
      sessionData: props.sessionData,
    };

    gtag("event", "question_loaded", {
      event_category: "order_journey",
      event_label: props.questionLoadedAnalyticsEventLabel || props.questionData.formName
    });
  }

  updateBackgroundColor() {
    const orderJourneyWrapper = document.getElementById("order-journey");
    if (
      ["InformationOnly", "Result", "Kickout"].includes(this.state.currentQuestion.type)
    ) {
      orderJourneyWrapper.classList.remove("bg--purple");
      orderJourneyWrapper.classList.add("bg--green");
    } else {
      orderJourneyWrapper.classList.add("bg--purple");
      orderJourneyWrapper.classList.remove("bg--green");
    }
  }

  renderMainContent() {
    const { currentQuestion, isLoading, error, transitionOut } = this.state;
    const Component = components[currentQuestion.type];
    this.updateBackgroundColor();

    return (
      <MainContentWrapper transitionOut={transitionOut}>
        {error ? (
          <ErrorMessage />
        ) : (
          <Component
            {...currentQuestion}
            isLoading={isLoading}
            key={this.state.currentQuestion.questionText}
            handleSubmit={this.handleSubmit}
          />
        )}
      </MainContentWrapper>
    );
  }

  showLoadingSpinner() {
    this.setState({ transitionOut: true });

    return window.setTimeout(() => {
      this.setState({ showLoadingSpinner: true });
    }, transitionOutDuration);
  }

  handleSubmit({ isNextCall }) {
    const { currentQuestion } = this.state;
    const { csrfToken } = this.props;
    const questionHasInlineValidation = questionsWithValidation.includes(
      currentQuestion.type
    );
    const mobileHeaderHeight = document.querySelector(".header-main")
      .clientHeight;

    const orderJourneyOffset =
      document.getElementById("order-journey").offsetTop - mobileHeaderHeight;
    const isSmallViewport = window.innerWidth < 768;

    this.setState({ isLoading: true });
    isSmallViewport && scrollTo(orderJourneyOffset, 200);
    if (!questionHasInlineValidation) {
      this.showLoadingSpinner();
    }

    callApi({
      isNextCall,
      csrfToken,
      outreach: this.props.outreach,
      onSuccess: data => {
        const transitionDelay =
          questionHasInlineValidation && data.error ? 0 : artificialLoadDelay;

        if (questionHasInlineValidation && !data.error) {
          this.showLoadingSpinner();
        }

        window.setTimeout(() => {
          window.clearTimeout(this.showLoadingSpinner);
          this.setState({
            currentQuestion: data.formData,
            error: false,
            isLoading: false,
            showLoadingSpinner: false,
            transitionOut: false,
            sessionData: data.sessionData,
          });
        }, transitionDelay);

        gtag("event", "question_loaded", {
          event_category: "order_journey",
          event_label: data.formData.questionLoadedAnalyticsEventLabel || data.formData.formName
        });
      },
      onFailure: () => {
        window.setTimeout(() => {
          window.clearTimeout(this.showLoadingSpinner);
          this.setState({
            error: true,
            isLoading: false,
            showLoadingSpinner: false,
            transitionOut: false
          });
        }, artificialLoadDelay);
      }
    });
  }

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    const { showLoadingSpinner, currentQuestion, sessionData } = this.state;
    const { debugEnabled, outreach } = this.props;
    const isKickout = currentQuestion.type === 'Kickout';
    const showDebug = debugEnabled && (<SessionContents sessionData={sessionData} />);
    const { outreach_path, outreach_order_path } = Routes;

    return (
      <>
        <QuestionWrapper center={isKickout}>
          {!isKickout && <Progress currentProgress={currentQuestion.progress} />}
          {showLoadingSpinner ? <Loading /> : this.renderMainContent()}
          {showDebug}

          {outreach && <EndLinksWrapper>
            {currentQuestion.isCompleteStep && <FadedSubText>{sessionData?.outreach_name}</FadedSubText> || <>
              <SubText>
                <Confirm
                  href={outreach_path()}
                  data-method="delete"
                  confirmText="Yes, end my session"
                  cancelText="No, go back to the current form"
                  heading="Are you sure?"
                  body="If you continue you will lose any unsubmitted data you have entered in this session and you will have to start a new session to keep registering rapid self-test kits."
                >End the session</Confirm>
              </SubText>

              <FadedSubText>{sessionData.outreach_name}</FadedSubText>

              <SubText>
                <Confirm
                  href={outreach_order_path()}
                  data-method="delete"
                  confirmText="Yes, register another kit"
                  cancelText="No, go back to the current form"
                  heading="Are you sure?"
                  body="If you continue you will lose all the data you entered for this kit registration."
                >Restart kit registration</Confirm>
              </SubText>
            </>}
          </EndLinksWrapper>}

        </QuestionWrapper>
      </>
    );
  }
}

Question.propTypes = {
  questionData: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  sessionData: PropTypes.object.isRequired,
  outreach: PropTypes.bool.isRequired
};

Question.defaultProps = {
  outreach: false
};

export default Question;
