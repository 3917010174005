import React from "react";
import PropTypes from "prop-types";
import Heading from "../../presentational_components/Heading";
import SubText from "../../presentational_components/SubText";
import List from "../../presentational_components/List";
import ListItem from "../../presentational_components/ListItem";
import styled from "styled-components";

const SubHeading = styled.h4`
  color: ${props => props.theme.white};
  font-size: 1.8rem;
  margin-bottom: 5px;
  font-weight: 500;
`;

class Result extends React.Component {
  render() {
    const {
      heading,
      orderSummary,
      weWillTextYouWhen,
      supportHeading,
      supportText
    } = this.props;

    return (
      <>
        {heading && <Heading>{heading}</Heading>}
        {orderSummary && <SubText>{orderSummary}</SubText>}
        {weWillTextYouWhen && (
          <>
            <SubHeading>We will text you when:</SubHeading>
            <List>
              {weWillTextYouWhen.map(listItem => {
                return <ListItem key={listItem}>{listItem}</ListItem>;
              })}
            </List>
          </>
        )}

        {supportHeading && supportText && (
          <>
            <SubHeading>{supportHeading}</SubHeading>
            <SubText dangerouslySetInnerHTML={{ __html: supportText }} />
          </>
        )}
      </>
    );
  }
}

Result.propTypes = {
  heading: PropTypes.string.isRequired,
  orderSummary: PropTypes.string.isRequired,
  weWillTextYouWhen: PropTypes.array,
  supportText: PropTypes.string.isRequired,
  supportHeading: PropTypes.string.isRequired
};

export default Result;
