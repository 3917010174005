import React, { useState, useReducer, useEffect } from "react";
import { hot } from "react-hot-loader";
import { ThemeProvider } from "styled-components";

import Navigation from "../presentational_components/Navigation"
import Progress from "../presentational_components/Progress";

import Progress0 from "./Progress0";
import Progress1 from "./Progress1";
import Progress2 from "./Progress2";

import theme from "../theme";

function Result({ sh24_uid, user_kit_code, csrfToken }) {
  useEffect(() => {
    const wrapper = document.getElementById("wrapper_outer");
    wrapper.classList.add("bg--purple");
    wrapper.classList.remove("bg--green");
  }, []);

  const formDataReducer = (state, action) => {
    return { ...state, [action.key]: { value: action.value, error: action.error } }
  };

  const uidProvided = Boolean(sh24_uid),
    [isLoading, setIsLoading] = useState(true),
    [showFullJourney, setShowFullJourney] = useState(!uidProvided),
    [currentProgress, setCurrentProgress] = useState(0),
    [formData, reduceFormData] = useReducer(formDataReducer, {
      sh24_uid: { value: sh24_uid || '', error: null },
      mobile_number: { value: '', error: null },
      user_kit_code: { value: user_kit_code || '', error: null },
      result: { value: '', error: null }
    }),
    [submittedResultError, setSubmittedResultError] = useState(false),
    [findOrderErrorCode, setFindOrderErrorCode] = useState(null);

  useEffect(() => {
    if (uidProvided) {
      fetchOrder().then(json => {
        setFormDataItems(json.formData);
        if (!hasErrors(json.formData)) {
          setCurrentProgress(1);
        } else {
          setFindOrderErrorCode(json.formData.errorCode);
          clearFormData();
          setShowFullJourney(true);
        }
        setIsLoading(false);
      })
    } else {
      setIsLoading(false);
    }
  }, []);

  const canSubmit = () => {
    switch (currentProgress) {
      case 0:
        return formData.mobile_number.value && formData.user_kit_code.value;
      case 1:
        return formData.mobile_number.value
          && formData.sh24_uid.value
          && formData.user_kit_code.value
          && formData.result.value;
      default:
        return false;
    }
  };

  const prevButtonHidden = () => {
    if (currentProgress == 0) return true;
    if (!showFullJourney && currentProgress == 1) return true;

    return false;
  }

  const handleSubmit = ({ isNextCall }) => {
    if (isNextCall && canSubmit() == false) { return; }

    if (isNextCall) {
      switch (currentProgress) {
        case 0:
          fetchOrder().then(json => {
            setFormDataItems(json.formData);
            if (!hasErrors(json.formData)) setCurrentProgress(1);
          });
          return;
        case 1:
          setSubmittedResultError(false);
          submitResult().then(json => {
            if (json == true) {
              setCurrentProgress(2)
            } else {
              setSubmittedResultError(true);
            }
          });
          return;
      }
    } else {
      setCurrentProgress(currentProgress - 1);
    }
  };

  const request = (method) => fetch('/result', {
    method,
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      result: {
        sh24_uid: formData.sh24_uid.value,
        mobile_number: formData.mobile_number.value,
        user_kit_code: formData.user_kit_code.value,
        result: formData.result.value
      }
    })
  }).then(data => data.json());

  const fetchOrder = () => request('POST');

  const submitResult = () => request('PATCH');

  const hasErrors = formData => Object.values(formData).some(e => e.error);

  const clearFormData = () => Object.keys(formData).forEach((key) => setFormDataItem(key, '', null));

  const setFormDataItem = (key, value, error = null) => reduceFormData({ key, value, error });

  const setFormDataItems = items => Object.entries(items).forEach(([k, v]) => setFormDataItem(k, v.value, v.error));

  const MILESTONES = 3;

  const renderNavigation = () => {
    if (currentProgress < MILESTONES - 1 && findOrderErrorCode != 403) {
      return < Navigation
        prevBtnHidden={prevButtonHidden()}
        nextBtnDisabled={!canSubmit()}
        handleSubmit={handleSubmit}
        isLoading={false}
        isLastStep={currentProgress == 1}
        completeLabelText="Submit"
      />
    }
  }

  const renderProgress = () => {
    if (showFullJourney && findOrderErrorCode != 403) {
      return <Progress milestones={MILESTONES} currentProgress={currentProgress} />
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {!isLoading &&
        <>
          {renderProgress()}

          {currentProgress == 0 && <Progress0 uidProvided={uidProvided} formData={formData} setFormDataItem={setFormDataItem} handleSubmit={handleSubmit} error={findOrderErrorCode} />}
          {currentProgress == 1 && <Progress1 formData={formData} setFormDataItem={setFormDataItem} error={submittedResultError} />}
          {currentProgress == 2 && <Progress2 formData={formData} />}

          {renderNavigation()}
        </>
      }
    </ThemeProvider>
  );
}

export default hot(module)(Result);
