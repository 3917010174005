// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "core-js/stable";
import "whatwg-fetch";

document.addEventListener("turbolinks:request-start", function (event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader(
    "X-Turbolinks-Nonce",
    $("meta[name='csp-nonce']").prop("content")
  );
});

document.addEventListener("turbolinks:before-cache", function () {
  $("script[nonce]").each(function (index, element) {
    $(element).attr("nonce", element.nonce);
  });
});

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");
require("jquery");
require("nav_main");
require("page_load_listener");
require("scroll_listener");
require("accordion");
require("tabs");
require("cookie_banner");

import $ from "jquery";
global.$ = jQuery;

import * as Routes from '../routes';
window.Routes = Routes;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
