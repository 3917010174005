import React, { useEffect } from 'react';
import { OutreachContext } from '../lib/OutreachContext';

import Heading from '../../presentational_components/Heading';
import SubText from '../../presentational_components/SubText';
import InputContainer from '../../presentational_components/InputContainer';
import StyledInput from '../../presentational_components/StyledInput';

function Name({ onKeyDown }) {
  const { state, dispatch } = React.useContext(OutreachContext);

  const handleChange = (e) => {
    dispatch({ type: 'setUserData', key: 'outreach_name', value: e.target.value });
  }

  useEffect(() => {
    dispatch({ type: 'setFormValid', value: state.userData.outreach_name.length >= 3 });
  }, [state.userData.outreach_name]);

  return (
    <>
      <Heading>What is your name?</Heading>
      <SubText>This helps us keep track of where and how rapid self-test kits are being given out.</SubText>

      <InputContainer>
        <StyledInput
          label='Name'
          attributeName='outreach_name'
          value={state.userData.outreach_name}
          onChange={handleChange}
          formName='outreach'
          autoFocus={true}
          onKeyDown={onKeyDown}
        />
      </InputContainer>
    </>
  );
}

export default Name;
