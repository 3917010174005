import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from "styled-components";

function font(level, theme) {
  if (level > 2) {
    return `
      font-family: ${theme.fontFamilyPrimary};
      font-weight: 400;
      font-size: ${2.4 + ((2 - level) / 3)}rem;

    `;
  }
  return `
    font-weight: 700;
    font-size: 2.4rem;
  `;
}
function Heading(props) {
  const { level, type, children, theme } = props;

  const H = styled(`h${level}`)`
    color: ${type === "kickout" ? theme.salmon : theme.offWhite};
    margin-bottom: 15px;
    ${font(level, theme)}
  `;

  return (
    <H {...props}>{children}</H>
  )
}

Heading.propTypes = {
  level: PropTypes.number.isRequired
};

Heading.defaultProps = {
  level: 2
}

export default withTheme(Heading);
