import React, { createContext, useReducer } from 'react';
import initialState from './initialState';
import reducer from './reducer';

export const OutreachContext = createContext({});

export const OutreachContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OutreachContext.Provider value={{ state, dispatch }}>
      {children}
    </OutreachContext.Provider>
  )
};
