import React from "react";
import PropTypes from "prop-types";
import ListItem from "../../presentational_components/ListItem";
import SubText from "../../presentational_components/SubText";
import Heading from "../../presentational_components/Heading";
import styled from "styled-components";
import AbstractQuestion from "./AbstractQuestion";
import LabTestBullet from "images/icons_testkit/lab_test_bullet.svg";
import RapidTestBullet from "images/icons_testkit/rapid_test_bullet.svg";

const TestDetailsList = styled.ul`
  list-style-type: none;
  margin: 20px 0px 0px 0px;
  padding: 0;

  li {
    background-image: url(${props => props.bulletPointIcon});
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 0 10px;
    list-style-type: none;
    padding: 8px 0px 8px 30px;
  }
`;

const MoreTestInformationLink = styled(SubText)`
  margin: 0px 0px 34px 0px;
`;

class RapidOrLabTestInfo extends React.Component {

  render() {
    const labTestDetails = <div>
      <Heading>You’re eligible for a lab test</Heading>
      <TestDetailsList bulletPointIcon={LabTestBullet}>
        <ListItem>Detects HIV 7 weeks or longer after infection</ListItem>
        <ListItem>You will need a few drops of blood from your fingertip</ListItem>
        <ListItem>Get a result 72 hours after you post your sample to our lab</ListItem>
        <ListItem>Great if you’d like someone else to interpret your result</ListItem>
        {this.props.eligibleForSyphilisLabTest && <ListItem>You can also test for syphilis if you want to</ListItem>}
      </TestDetailsList>
      <MoreTestInformationLink>
        <a href={Routes.static_pages_whats_in_the_kit_path()+"#labtest"} target={"_blank"} title={"opens in a new window/tab"}>
          Read more about the lab test
        </a>
      </MoreTestInformationLink>
    </div>

    const rapidTestDetails = <div>
      <Heading>You’re eligible for a rapid self-test</Heading>
      <TestDetailsList bulletPointIcon={RapidTestBullet}>
        <ListItem>Detects HIV 12 weeks or longer after infection</ListItem>
        <ListItem>You need only 1 drop of blood from your fingertip</ListItem>
        <ListItem>Read your own result immediately at home</ListItem>
        <ListItem>Great for testing quickly and regularly</ListItem>
        {this.props.eligibleForSyphilisLabTest && <ListItem>Only tests for HIV and doesn’t test for syphilis</ListItem>}
      </TestDetailsList>
      <MoreTestInformationLink>
        <a href={Routes.static_pages_whats_in_the_kit_path()+"#rapidtest"} target={"_blank"} title={"opens in a new window/tab"}>
          Read more about the rapid self-test
        </a>
      </MoreTestInformationLink>
    </div>

    return (
      <>
        {this.props.eligibleForHivLabTest && labTestDetails}
        {this.props.eligibleForHivRapidTest && rapidTestDetails}
        <AbstractQuestion
          {...this.props}
          handleSubmit={this.props.handleSubmit}
          canProgress={true}
        ></AbstractQuestion>
      </>
    );
  }
}

RapidOrLabTestInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

export default RapidOrLabTestInfo;
