import React, { useEffect } from 'react';
import { OutreachContext } from '../lib/OutreachContext';

import Heading from '../../presentational_components/Heading';
import SubText from '../../presentational_components/SubText';
import InputContainer from '../../presentational_components/InputContainer';
import StyledInput from '../../presentational_components/StyledInput';

function Email({ onKeyDown }) {
  const { state, dispatch } = React.useContext(OutreachContext);

  const handleChange = (e) => {
    dispatch({ type: 'setUserData', key: 'outreach_email', value: e.target.value });
  }

  const EMAIL_REGEX = /^([^ ]+)@([^ ]+)\.([^ ]+)$/;

  useEffect(() => {
    dispatch({ type: 'setFormValid', value: EMAIL_REGEX.test(state.userData.outreach_email) });
  }, [state.userData.outreach_email]);

  return (
    <>
      <Heading>What is your email address?</Heading>
      <SubText>This should be the email you use for the organisation you are giving out kits on behalf of.</SubText>

      <InputContainer>
        <StyledInput
          label='Email'
          attributeName='outreach_email'
          inputType='email'
          value={state.userData.outreach_email}
          onChange={handleChange}
          formName='outreach'
          autoFocus={true}
          onKeyDown={onKeyDown}
        />
      </InputContainer>
    </>
  );
}

export default Email;
