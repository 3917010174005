import React from 'react';

import Heading from "../presentational_components/Heading";
import SubText from "../presentational_components/SubText";
import LinkText from  "../presentational_components/LinkText"
import Button from "../presentational_components/Button";

function Reactive() {
  return (
    <>
      <Heading>Your result has been submitted</Heading>
      <SubText>A reactive result is not a diagnosis. You will need to go to a clinic to give another blood sample so that they can confirm your result.</SubText>
      <SubText>We would also advise abstaining from sex until you have received your result from the clinic. We will text you a link you can use to book a call if you would like to discuss your result with one of our clinical team.</SubText>
      <SubText>
        <Button href={Routes.static_pages_specialist_services_path()} colour="purple">Find your local clinic</Button>
      </SubText>

      <SubText>
        <Button href="/about-hiv" colour="purple">Find out about HIV</Button>
      </SubText>

    </>
  )
}

export default Reactive;
