import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AbstractQuestion from "../question_components/AbstractQuestion";
import TextInput from "../../presentational_components/TextInput";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";

const AnswersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    flex-direction: row;
  }
`;

const NameWrapper = styled.div`
  flex-basis: 48%;
  margin-bottom: 20px;
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    margin-bottom: 0;
  }
`;

class Name extends React.Component {
  constructor(props) {
    super(props);
    const { inputs } = props;
    const [firstName, secondName] = inputs;
    const firstNameInitialValue = firstName.value;
    const secondNameInitialValue = secondName.value;

    this.state = {
      firstNameValue: firstNameInitialValue ? firstNameInitialValue : "",
      secondNameValue: secondNameInitialValue ? secondNameInitialValue : ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    const { formName, error, inputs } = this.props;
    const { firstNameValue, secondNameValue } = this.state;
    const canProgress = firstNameValue && secondNameValue;
    const [firstName, secondName] = inputs;

    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={!!canProgress}
      >
        <AnswersContainer>
          <NameWrapper>
            <TextInput
              value={firstNameValue}
              onChange={event => {
                this.setState({ firstNameValue: event.target.value });
              }}
              label={firstName.label}
              formName={formName}
              attributeName={firstName.attributeName}
              invalid={!!error}
            />
            {firstName.error && <ErrorFeedback message={firstName.error} />}
          </NameWrapper>
          <NameWrapper>
            <TextInput
              value={secondNameValue}
              onChange={event => {
                this.setState({ secondNameValue: event.target.value });
              }}
              label={secondName.label}
              formName={formName}
              attributeName={secondName.attributeName}
              invalid={!!error}
            />
            {secondName.error && <ErrorFeedback message={secondName.error} />}
          </NameWrapper>
        </AnswersContainer>
      </AbstractQuestion>
    );
  }
}

Name.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  inputs: PropTypes.array.isRequired
};

export default Name;
