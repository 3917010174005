import React from "react";
import PropTypes from "prop-types";

import Heading from "../../presentational_components/Heading";
import Navigation from "../../presentational_components/Navigation";
import SubText from "../../presentational_components/SubText";
import WithFadeIn from "../../hoc/WithFadeIn";

class AbstractQuestion extends React.Component {
  render() {
    const {
      questionText,
      handleSubmit,
      canProgress,
      children,
      isLoading,
      clarificationText,
      isLastStep,
      formName,
      required
    } = this.props;
    const prevBtnHidden = ['terms_and_conditions_form', 'outreach_intro_form'].includes(formName);
    const nextBtnDisabled = (required && !canProgress) || isLoading;

    return (
      <>
        {questionText && <Heading>{questionText}</Heading>}
        {clarificationText && <SubText>{clarificationText}</SubText>}
        <form id="order-journey-form">
          {children}
          <Navigation
            isLoading={isLoading}
            nextBtnDisabled={nextBtnDisabled}
            handleSubmit={handleSubmit}
            prevBtnHidden={prevBtnHidden}
            isLastStep={isLastStep}
          />
        </form>
      </>
    );
  }
}

AbstractQuestion.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  questionText: PropTypes.string,
  clarificationText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  canProgress: PropTypes.bool,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};

AbstractQuestion.defaultProps = {
  required: true
};

export default WithFadeIn(AbstractQuestion);
