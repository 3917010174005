import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  transition: opacity 0.5s;
  opacity: ${props => (props.transitionIn ? 1 : 0)};
`;

const WithFadeIn = WrappedComponent => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        transitionIn: false
      };
    }

    componentDidMount() {
      window.setTimeout(() => this.setState({ transitionIn: true }), 1);
    }

    componentWillUnmount() {
      this.setState({ transitionIn: false });
    }

    render() {
      return (
        <Wrapper transitionIn={this.state.transitionIn}>
          <WrappedComponent {...this.props} />
        </Wrapper>
      );
    }
  }

  return HOC;
};

export default WithFadeIn;
