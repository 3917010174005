const initialState = {
  progress: 0,
  formValid: false,
  userData: {
    outreach_name: '',
    outreach_email: '',
    outreach_organisation: '',
    outreach_region: ''
  }
};

export default initialState;
