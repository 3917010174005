import React from 'react';

import Heading from "../presentational_components/Heading";
import SubText from "../presentational_components/SubText";
import Button from "../presentational_components/Button";

function Failed() {
  return (
    <>
      <Heading>Your result has been submitted</Heading>
      <SubText>Unfortunately your test kit didn't work. This happens sometimes.</SubText>
      <SubText>If you'd like a new kit you can reply NEW KIT to one of our text messages or text 07860 064 295.</SubText>
      <SubText>You can also text us if you are anxious or have any questions.</SubText>
      <SubText>
        <Button href={Routes.static_pages_about_hiv_path() + "#preventing"} colour="purple">Preventing and testing for HIV</Button>
      </SubText>
    </>
  )
}

export default Failed;
