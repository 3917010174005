import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AsyncSelect from "react-select/async";

import AbstractQuestion from "../question_components/AbstractQuestion";
import TextInput from "../../presentational_components/TextInput";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";
import reactSelectStyles from "../../../reactSelectStyles";

const AnswersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 340px;
`;

const AddressLineWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    margin-bottom: 0;
  }
`;

const CityPostcodeWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    display: flex;
  }
`;

const CityInputWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    width: 63%;
    margin-right: 2%;
  }
`;

const PostcodeInputWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    width: 35%;
  }
`;

class Address extends React.Component {
  constructor(props) {
    super(props);
    const { inputs, postcodeDisabled } = props;
    const [addressLine1, addressLine2, addressLine3, postcode] = inputs;
    const addressLine1Value = addressLine1.value;
    const addressLine2Value = addressLine2.value;
    const addressLine3Value = addressLine3.value;
    const postcodeValue = postcode.value;

    this.state = {
      addressLine1Value: addressLine1Value ? addressLine1Value : "",
      addressLine2Value: addressLine2Value ? addressLine2Value : "",
      addressLine3Value: addressLine3Value ? addressLine3Value : "",
      postcodeValue: postcodeValue ? postcodeValue : "",
      addressQuery: "",
      showManualAddress: addressLine1Value || addressLine2Value || addressLine3Value || (!postcodeDisabled && postcodeValue),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  addressQueryTimeout = null

  render() {
    const { formName, error, inputs, required, postcodeDisabled, placeholder } = this.props;
    const {
      addressLine1Value,
      addressLine2Value,
      addressLine3Value,
      postcodeValue,
      showManualAddress,
    } = this.state;
    const canProgress = (addressLine1Value && addressLine3Value && postcodeValue) || !required;
    const [addressLine1, addressLine2, addressLine3, postcode] = inputs;

    const addressSearchConfig = {
      placeholder: placeholder,
      styles: reactSelectStyles,
      onChange: (selected) => {
        if (selected['value']) {
          fetch('/address-search/retrieve?' + new URLSearchParams({ id: selected['value'] }))
            .then((response) => response.json())
            .then((data) => {
              this.setState({ addressLine1Value: data['line_1'] });
              this.setState({ addressLine2Value: data['line_2'] });
              this.setState({ addressLine3Value: data['line_3'] });
              if (!postcodeDisabled) this.setState({ postcodeValue: data['postcode'] });
            })
        }
        this.setState({ showManualAddress: true });
      },
      onInputChange: (value, metaAction) => {
        if (metaAction['action'] == 'input-change') {
          this.setState({ addressQuery: value });
        }
      },
      loadOptions: (addressQuery, callback) => {
        const { inputs: [_1, _2, _3, postcode] } = this.props

        clearTimeout(this.addressQueryTimeout)
        this.addressQueryTimeout = setTimeout(() => {
          fetch('/address-search?' + new URLSearchParams({ query: addressQuery, postcode: postcode.value || '' }))
            .then((response) => response.json())
            .then((data) => {
              if (data.length == 0) {
                data = [{ value: null, label: 'enter address manually' }]
              }
              callback(data)
            })
          }, 300)
      },
    }

    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={!!canProgress}
      >
        <AnswersContainer>

          {!showManualAddress &&
            <AsyncSelect
              styles={addressSearchConfig.styles}
              placeholder={addressSearchConfig.placeholder}
              onChange={addressSearchConfig.onChange}
              onInputChange={addressSearchConfig.onInputChange}
              loadOptions={addressSearchConfig.loadOptions}
              autoFocus={true}
            />
            ||
            <>
              <AddressLineWrapper>
                <TextInput
                  value={addressLine1Value}
                  onChange={event => {
                    this.setState({ addressLine1Value: event.target.value });
                  }}
                  label={addressLine1.label}
                  formName={formName}
                  attributeName={addressLine1.attributeName}
                  invalid={!!error}
                  autocomplete={addressLine1.autocomplete}
                  autoFocus={true}
                />
                {addressLine1.error && (
                  <ErrorFeedback message={addressLine1.error} />
                )}

                <TextInput
                  value={addressLine2Value}
                  onChange={event => {
                    this.setState({ addressLine2Value: event.target.value });
                  }}
                  label={addressLine2.label}
                  formName={formName}
                  attributeName={addressLine2.attributeName}
                  invalid={!!error}
                  autocomplete={addressLine2.autocomplete}
                />
                {addressLine2.error && (
                  <ErrorFeedback message={addressLine2.error} />
                )}
              </AddressLineWrapper>
              <CityPostcodeWrapper>
                <CityInputWrapper>
                  <TextInput
                    value={addressLine3Value}
                    onChange={event => {
                      this.setState({ addressLine3Value: event.target.value });
                    }}
                    label={addressLine3.label}
                    formName={formName}
                    attributeName={addressLine3.attributeName}
                    invalid={!!error}
                    autocomplete={addressLine3.autocomplete}
                  />
                  {addressLine3.error && (
                    <ErrorFeedback message={addressLine3.error} />
                  )}
                </CityInputWrapper>
                <PostcodeInputWrapper>
                  <TextInput
                    value={postcodeValue}
                    onChange={event => {
                      this.setState({ postcodeValue: event.target.value });
                    }}
                    label={postcode.label}
                    formName={formName}
                    attributeName={postcode.attributeName}
                    disabled={postcodeDisabled}
                    autocomplete={postcode.autocomplete}
                  />
                  {postcode.error && (
                    <ErrorFeedback message={postcode.error} />
                  )}
                </PostcodeInputWrapper>
              </CityPostcodeWrapper>
            </>
          }
        </AnswersContainer>
      </AbstractQuestion>
    );
  }
}

Address.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  inputs: PropTypes.array.isRequired,
  required: PropTypes.bool,
  postcodeDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired
};

Address.defaultProps = {
  required: true,
  postcodeDisabled: true
};

export default Address;
