import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';

import LinkText from './LinkText';
import Heading from './Heading';
import SubText from './SubText';
import Button from './Button';
import VerticallyCentered from './VerticallyCentered';

import Cross from "../svg/cross.svg";

const StyledCross = styled(Cross).attrs({ role: 'link' })`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
`;

const modalStyles = {
  overlay: {
    backgroundColor: '#000000cc'
  },
  content: {
    border: 'none',
    background: '#2f2f81',
    color: 'white',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minWidth: '300px',
    maxWidth: '650px',
    width: '75%',
    minHeight: '40%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: '40px'
  },
};

Modal.setAppElement('#order-journey')

function Confirm({ heading, body, confirmText, cancelText, children, ...linkAttributes }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = e => {
    e.nativeEvent.preventDefault();
    setIsOpen(true);
  };

  const handleCloseModal = e => {
    e.nativeEvent.preventDefault();
    setIsOpen(false);
  };

  return (<>
    <LinkText href="#" onClick={handleOpenModal}>{children}</LinkText>

    <Modal isOpen={isOpen} style={modalStyles}>
      <StyledCross onClick={handleCloseModal} />

      <VerticallyCentered>
        <Heading>{heading}</Heading>

        <SubText>{body}</SubText>

        <SubText>
          <Button {...linkAttributes}>{confirmText}</Button>
        </SubText>

        <SubText>
          <LinkText href="#" onClick={handleCloseModal}>{cancelText}</LinkText>
        </SubText>
      </VerticallyCentered>
    </Modal>
  </>);
};

Confirm.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

Confirm.defaultProps = {
  heading: 'Are you sure?',
  body: 'If you\'re sure, press Confirm',
  confirmText: 'Confirm',
  cancelText: 'Cancel'
}

export default Confirm;
