import React from "react";
import PropTypes from "prop-types";
import Heading from "../../presentational_components/Heading";
import SubText from "../../presentational_components/SubText";
import AbstractQuestion from "../question_components/AbstractQuestion";
import Button from "../../presentational_components/Button";
import LinkText from "../../presentational_components/LinkText";

class InformationOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        skip_section: 'no'
      }
    };
    this.skipSection = this.skipSection.bind(this);
  }

  createMarkup() {
    return { __html: this.props.informationText };
  }

  skipSection(e) {
    e.preventDefault();
    const { values } = this.state;

    this.setState(
      { values: { ...values, skip_section: 'yes' } },
      () => this.props.handleSubmit({ isNextCall: true })
    );
  }

  render() {
    const { cta, heading, formName, skippable } = this.props;

    return (
      <>
        {heading && <Heading>{heading}</Heading>}
        <SubText dangerouslySetInnerHTML={this.createMarkup()}></SubText>
        {cta && <Button href={cta.path}>{cta.text}</Button>}

        <AbstractQuestion
          {...this.props}
          handleSubmit={this.props.handleSubmit}
          canProgress={true}
        >
          {skippable && <SubText>
            <input value={this.state.values.skip_section} type="hidden" name={`${formName}[skip_section]`} id={`${formName}_skip_section`} />

            <LinkText href="#" onClick={this.skipSection}>Skip section</LinkText>
          </SubText>}
        </AbstractQuestion>
      </>
    );
  }
}

InformationOnly.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  informationText: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  cta: PropTypes.object
};

export default InformationOnly;
