import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from './Checkbox';

const FlexDiv = styled.div`
  display: flex;
  margin: 1em 0;
`;

function CheckboxWithLabel({ checked, onChange, name, id, children }) {
  return (<FlexDiv>
    <Checkbox checked={checked} onChange={onChange} name={name} id={id} />
    <label htmlFor={id}>{children}</label>
  </FlexDiv>);
}

CheckboxWithLabel.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired

}
export default CheckboxWithLabel;

