import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { hot } from "react-hot-loader";

import GoogleMap from "./GoogleMap";
import SearchBox from "./SearchBox";
import SubmitButton from "./SubmitButton";
import LocationList from "./LocationList";
import theme from "../theme";
import ErrorFeedback from "../presentational_components/ErrorFeedback";

const ErrorFeedbackWrapper = styled.div`
  max-width: 375px;
  margin: 0 auto;
`;

const SearchForm = styled.form`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
`;

class ServiceSearch extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      mappedLocations: null,
      postcodeValue: "",
      invalidSearch: false,
      isLoading: false
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { postcodeValue } = this.state;
    this.setState({ isLoading: true });
    fetch(`clinics/search?postcode=${postcodeValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        if (!myJson) {
          this.setState({
            isLoading: false,
            invalidSearch: true,
            postcodeValue: "",
            mappedLocations: null
          });
          return;
        }

        this.setState({
          isLoading: false,
          invalidSearch: false,
          mappedLocations: myJson.value.map(provider => {
            const [lng, lat] = provider.Geocode.coordinates;
            return {
              name: provider.OrganisationName || "Clinic",
              telephone: provider.telephone || false,
              address: [provider.Address1, provider.Address2, provider.Address3]
                .filter(n => n)
                .join(", "),
              link: provider.URL && (provider.URL.startsWith('http') ? provider.URL : `https://${provider.URL}`),
              lat,
              lng
            };
          })
        });
      });
  }

  handleChange(event) {
    this.setState({ postcodeValue: event.target.value });
  }

  render() {
    const {
      mappedLocations,
      postcodeValue,
      invalidSearch,
      isLoading
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          <SearchForm onSubmit={this.handleSubmit}>
            <SearchBox
              isLoading={isLoading}
              placeholder={"Postcode"}
              aria-label={"Postcode"}
              onChange={this.handleChange}
              value={postcodeValue}
            />
            <SubmitButton />
          </SearchForm>
          {invalidSearch && !mappedLocations ? (
            <ErrorFeedbackWrapper>
              <ErrorFeedback
                message={`We can't find that postcode. Please try again.`}
              />
            </ErrorFeedbackWrapper>
          ) : (
            <>
              <GoogleMap mappedLocations={mappedLocations} />
              <LocationList mappedLocations={mappedLocations} />
            </>
          )}
        </>
      </ThemeProvider>
    );
  }
}

export default hot(module)(ServiceSearch);
