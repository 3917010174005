import React from "react";
import styled from "styled-components";
import Chevron from "../svg/chevron_right.svg";

const GreenChevron = styled(Chevron)`
  width: 16px;
  stroke: ${props => props.theme.salmon};
  * {
    stroke: ${props => props.theme.green};
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default function SubmitButton() {
  return (
    <Button type="submit" aria-label="submit">
      <GreenChevron />
    </Button>
  );
}
