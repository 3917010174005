import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Chevron from "../svg/chevron_right.svg";

const StyledChevron = styled(Chevron)`
  width: 16px;
  stroke: ${props => props.theme.salmon};
  margin-left: ${props => (props.direction === "right" ? `10px` : `0px`)};
  transform: ${props => (props.direction === "left" ? `rotate(180deg)` : null)};
  margin-right: ${props => (props.direction === "left" ? `10px` : null)};
`;

const Btn = styled.button`
  padding: 0;
  color: ${props => props.theme.salmon};
  background: transparent;
  border: none;
  font-family: ${props => props.theme.fontFamilyPrimary};
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  visibility: ${props => (props.hidden ? "hidden" : "visible")};
  &[disabled] {
    opacity: 0.4;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

function Navigation({ nextBtnDisabled, handleSubmit, prevBtnHidden, isLoading, isLastStep, completeLabelText = 'Complete' }) {
  const completeLabel = () => {
    return isLastStep ? completeLabelText : 'Next'
  };

  return (
    <ControlsWrapper>
      <Btn
        type="button"
        disabled={isLoading}
        hidden={prevBtnHidden}
        onClick={event => {
          event.preventDefault();
          handleSubmit({ isNextCall: false });
        }}
      >
        <StyledChevron direction={"left"} />
        Back
      </Btn>
      <Btn
        type="submit"
        disabled={nextBtnDisabled}
        onClick={event => {
          event.preventDefault();
          handleSubmit({ isNextCall: true });
        }}
      >
        {completeLabel()}
        <StyledChevron direction={"right"} />
      </Btn>
    </ControlsWrapper>
  );
}

Navigation.propTypes = {
  nextBtnDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  prevBtnHidden: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
};

Navigation.defaultProps = {
  isLastStep: false
};

export default Navigation;
