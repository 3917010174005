import styled from "styled-components";

const SubText = styled.p`
  color: ${props =>
    props.type === "kickout" ? props.theme.salmon : props.theme.offWhite};
  font-size: 1.6rem;
  font-weight: 300;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  a {
    color: ${props => props.theme.white};
  }

  li {
    color: ${props => props.theme.white};
  }

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    display: ${props => props.centered ? 'flex' : 'block'};
    justify-content: ${props => props.centered ? 'center' : 'left'};
  }
`;

export default SubText;
