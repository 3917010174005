import React from "react";
import PropTypes from "prop-types";

import Heading from "../presentational_components/Heading";
import SubText from "../presentational_components/SubText";
import InputContainer from "../presentational_components/InputContainer";
import StyledInput from "../presentational_components/StyledInput"
import ErrorFeedback from "../presentational_components/ErrorFeedback";
import LinkText from "../presentational_components/LinkText"

function Progress0({ uidProvided, formData, setFormDataItem, handleSubmit, error }) {
  const handleKeyDown = (e) => {
    const isEnter = e.keyCode == 13;

    isEnter && handleSubmit({ isNextCall: true })
  }

  const handleOnChange = (e) => {
    const paramName = e.target.name.match(/result\[(.*)\]/)[1];
    let paramValue = e.target.value;

    if (paramName == 'mobile_number') paramValue = paramValue.replace(/[^+ 0-9]/, '');
    if (paramName == 'user_kit_code') paramValue = paramValue.toUpperCase().replace(/[^A-Z0-9]/, '');

    setFormDataItem(paramName, paramValue);
  };

  gtag("event", "result_page_loaded", {
    event_category: "result_journey",
    event_label: "result_landing_page"
  });
  const renderForm = () => {
    return (
      <>
        <InputContainer>
          <StyledInput
            label="Mobile phone number"
            attributeName="mobile_number"
            inputType="tel"
            value={formData.mobile_number.value}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            formName="result"
          />

          {formData.mobile_number.error && <ErrorFeedback message={formData.mobile_number.error} />}
        </InputContainer>

        <InputContainer>
          <StyledInput
            label="Unique kit code"
            attributeName="user_kit_code"
            value={formData.user_kit_code.value}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            formName="result"
          />

          {formData.user_kit_code.error && <ErrorFeedback message={formData.user_kit_code.error} />}
        </InputContainer>

        <SubText>If you don't have either of these details, <LinkText href={Routes.static_pages_result_help_path()}>get help with reporting your result</LinkText>.</SubText>
      </>
    )
  }

  return (
    <>
      {uidProvided &&
        <>
          {error == 403 &&
            <>
              <Heading>That order cannot have a result reported</Heading>
              <SubText>Cannot report result for this order, please <LinkText href={Routes.static_pages_result_help_path()}>contact support for further assistance</LinkText>.</SubText>
            </>
          }
          {error == 404 &&
            <>
              <Heading>That code and mobile number don't match</Heading>
              <SubText>You can find your code in a text message from us. You will need to use the same mobile number you used to order the test kit.</SubText>
            </>
          }
          {error == null &&
            <>
              <Heading>Enter your mobile phone number and unique code</Heading>
              <SubText>You can find your code in a text message from us.</SubText>
            </>
          }
        </>
      }

      {error != 403 && renderForm()}
    </>
  )
}

Progress0.propTypes = {
  uidProvided: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormDataItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

Progress0.defaultProps = {
  handleSubmit: () => { }
};

export default Progress0;
