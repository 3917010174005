export default [
  {
    name: "Test",
    distance: 0.2,
    telephone: "02071889999",
    lat: 50.8260299,
    lng: -0.1690911,
    address: "2222 Burrell Street, Bankside, London, SE1 0UN",
    link: "http://www.google.com"
  },
  {
    name: "Test2",
    distance: 0.5,
    telephone: "02071889999",
    lat: 50.8326406,
    lng: -0.1614266,
    address: "4-5 fdsfdfdf Street, Bankside, London, SE1 0UN",
    link: "http://www.google.com"
  },
  {
    name: "Test3",
    distance: 0.9,
    telephone: "02071889999",
    lat: 50.8216902,
    lng: -0.1392774,
    address: "100 Burrell Street, Bankside, London, SE1 0UN",
    link: "http://www.google.com"
  }
];
