import React, { useEffect } from "react";
import PropTypes from "prop-types";

import WithFadeIn from "../hoc/WithFadeIn";

import Reactive from "./Reactive";
import Negative from "./Negative";
import Failed from "./Failed";

function Progress2({ formData }) {
  useEffect(() => {
    const wrapper = document.getElementById("wrapper_outer");
    wrapper.classList.remove("bg--purple");
    wrapper.classList.add("bg--green");
  }, []);

  const components = {
    reactive: <Reactive />,
    negative: <Negative />,
    failed: <Failed />
  };

  gtag("event", "result_page_loaded", {
    event_category: "result_journey",
    event_label: "result_followup_page"
  });

  return components[formData.result.value];
}

Progress2.propTypes = {
  formData: PropTypes.object.isRequired
};

export default WithFadeIn(Progress2);
