const reducer = (state, action) => {
  switch(action.type) {
    case 'setFormValid': {
      return { ...state, formValid: action.value };
    }
    case 'incrementProgress': {
      return { ...state, progress: state.progress + 1 };
    }
    case 'decrementProgress': {
      return { ...state, progress: state.progress - 1 };
    }
    case 'setUserData': {
      return { ...state, userData: { ...state.userData, [action.key]: action.value } };
    }
    default: {
      throw new Error(`Invalid action ${action.type}`);
    }
  }
};

export default reducer;
