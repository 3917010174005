import React, { useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useMediaQuery } from 'react-responsive';
import PropTypes from "prop-types";
import ListItem from "../../presentational_components/ListItem";
import SubText from "../../presentational_components/SubText";
import Heading from "../../presentational_components/Heading";
import styled, { withTheme } from "styled-components";
import MultipleChoice from "./MultipleChoice";
import LabTestBullet from "images/icons_testkit/lab_test_bullet.svg";
import RapidTestBullet from "images/icons_testkit/rapid_test_bullet.svg";

const TabListWrapper = styled(TabList)`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;


const TabWrapper = styled(Tab)`
  flex: 1 1 0px;
  border: 1px solid ${props => props.theme.white};
  color: ${props => props.theme.white};
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 16px 0px;
  position: relative;

  &:focus-visible {
      outline: none;
    }

  &.is-selected {
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.purple};

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: ${props => props.theme.white};
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
      }
    }
  }

`;

const TestDetailsList = styled.ul`
  list-style-type: none;
  margin: 20px 0px 0px 0px;
  padding: 0;

  li {
    background-image: url(${props => props.bulletPointIcon});
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 0 10px;
    list-style-type: none;
    padding: 8px 0px 8px 30px;
  }
`;

const MoreTestInformationLink = styled(SubText)`
  margin-bottom: 34px;
`;

const ColumnWrapper = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
`;

const RapidOrLabTestChoice = (props) => {

  const multipleChoiceRef = useRef();
  const { eligibleForSyphilisLabTest, theme } = props;

  const onTabSelect = () => {
    multipleChoiceRef.current.handleChange({ target: { value: null } });
  };

  const renderLabTestInfo = () => {
    return (
      <>
        <TestDetailsList bulletPointIcon={LabTestBullet}>
          <ListItem>Detects HIV 7 weeks or longer after infection</ListItem>
          <ListItem>You will need a few drops of blood from your fingertip</ListItem>
          <ListItem>Get a result 72 hours after you post your sample to our lab</ListItem>
          <ListItem>Great if you’d like someone else to interpret your result</ListItem>
          {eligibleForSyphilisLabTest && <ListItem>You can also test for syphilis if you want to</ListItem>}
        </TestDetailsList>
        <MoreTestInformationLink>
          <a href={Routes.static_pages_whats_in_the_kit_path() + "#labtest"} target={"_blank"} title={"opens in a new window/tab"}>
            Read more about the lab test
          </a>
        </MoreTestInformationLink>
      </>
    )
  };

  const renderRapidTestInfo = () => {
    return (
      <>
        <TestDetailsList bulletPointIcon={RapidTestBullet}>
          <ListItem>Detects HIV 12 weeks or longer after infection</ListItem>
          <ListItem>You need only 1 drop of blood from your fingertip</ListItem>
          <ListItem>Read your own result immediately at home</ListItem>
          <ListItem>Great for testing quickly and regularly</ListItem>
          {eligibleForSyphilisLabTest && <ListItem>Only tests for HIV and doesn’t test for syphilis</ListItem>}
        </TestDetailsList>
        <MoreTestInformationLink>
          <a href={Routes.static_pages_whats_in_the_kit_path() + "#rapidtest"} target={"_blank"} title={"opens in a new window/tab"}>
            Read more about the rapid self-test
          </a>
        </MoreTestInformationLink>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <>
        <SubText>Find out more about the tests available using the tabs below.</SubText>

        <Tabs
          selectedTabClassName='is-selected'
          selectedTabPanelClassName='is-selected'
          onSelect={onTabSelect}
        >
          <TabListWrapper>
            <TabWrapper>Lab Test</TabWrapper>
            <TabWrapper>Rapid self-test</TabWrapper>
          </TabListWrapper>

          <TabPanel>
            {renderLabTestInfo()}
          </TabPanel>
          <TabPanel>
            {renderRapidTestInfo()}
          </TabPanel>
        </Tabs>
      </>
    )
  };

  const renderColumns = () => {
    return (
      <ColumnWrapper>
        <Column>
          <Heading level={3}>Lab test</Heading>
          {renderLabTestInfo()}
        </Column>
        <Column>
          <Heading level={3}>Rapid self-test</Heading>
          {renderRapidTestInfo()}
        </Column>
      </ColumnWrapper>
    )
  };

  const isBreakpointMedium = useMediaQuery({ query: `(min-width: ${theme.breakpointMedium})` })

  return (
    <MultipleChoice {...props} ref={multipleChoiceRef} columns>
      {isBreakpointMedium && renderColumns() || renderTabs()}
    </MultipleChoice>
  );
}

RapidOrLabTestChoice.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired
};

export default withTheme(RapidOrLabTestChoice);
