const theme = {
  purple: "#2f2f81",
  purpleLight: "#595798",
  purpleDark: "#20206d",
  green: "#2f7f81",
  greenDark: "#225e5f",
  white: "#fff",
  whiteOpaque: "rgba(255, 255, 255, 0.1)",
  offWhite: "#fefbf9",
  fontFamilyPrimary: `"IBM Plex Sans", sans-serif`,
  grey: "#505062",
  salmon: "#f2a099",
  red: "#EC6E64",
  breakpointMedium: "768px",
  breakpointSmall: "640px"
};

export default theme;
