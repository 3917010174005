import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Heading from "../../presentational_components/Heading";
import SubText from "../../presentational_components/SubText";
import Button from "../../presentational_components/Button";
import WithFadeIn from "../../hoc/WithFadeIn";

const KickoutWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    display: flex;
    justify-content: center;
  }
`;

const CopyWrapper = styled.div`
  margin-bottom: 20px;
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    flex-basis: 48%;
    margin-bottom: 0;
  }
`;

const FindClinicButton = styled(Button)`
  align-self: flex-end;
  background-color: ${props => props.theme.purple};
  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    margin-left: 30px;
  }
  &:hover {
    background-color: ${props => props.theme.purpleLight};
  }
`;

class Kickout extends React.Component {
  constructor(props) {
    super(props);

    gtag("event", props.reason, {
      event_category: "kickout",
      event_label: props.admin_district
    });
  }

  render() {
    const { heading, subtitle } = this.props;

    return (
      <KickoutWrapper>
        <CopyWrapper>
          <Heading
            type="kickout"
            dangerouslySetInnerHTML={{__html: heading}}
          />

          <SubText
            dangerouslySetInnerHTML={{__html: subtitle}}
          />
        </CopyWrapper>
        <FindClinicButton
          target={"_blank"}
          href={
            "https://test.tht.org.uk/finder"
          }
        >
          Find your local service
        </FindClinicButton>
      </KickoutWrapper>
    );
  }
}

Kickout.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  reason: PropTypes.string
};

export default WithFadeIn(Kickout);
