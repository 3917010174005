import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Map, InfoWindow, Marker } from "google-maps-react";
import Pin from "images/icons_map/map_pin.svg";
import MapBubble from "./MapBubble";

const MapContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  display: ${props => (props.visible ? "block" : "none")};
  margin-bottom: 20px;

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    height: 500px;
    margin-bottom: 40px;
  }
`;

class GoogleMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      bounds: {}
    };

    this.onReady = this.onReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { mappedLocations } = this.props;

    if (mappedLocations !== prevProps.mappedLocations) {
      this.makeBounds();
    }
  }

  onReady() {
    this.makeBounds();
  }

  makeBounds() {
    const { mappedLocations } = this.props;
    if (!mappedLocations) return;

    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < mappedLocations.length; i++) {
      bounds.extend(mappedLocations[i]);
    }

    this.setState({ bounds });
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });
  };

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  render() {
    const { mappedLocations } = this.props;
    const {
      selectedPlace,
      showingInfoWindow,
      activeMarker,
      bounds
    } = this.state;

    return (
      <MapContainer visible={mappedLocations}>
        <Map
          onReady={this.onReady}
          bounds={bounds}
          google={window.google}
          onClick={this.onMapClicked}
          style={{
            height: "100%",
            position: "relative",
            width: "100%"
          }}
          zoom={14}
        >
          {mappedLocations &&
            mappedLocations.map(location => {
              return (
                <Marker
                  name={location.name}
                  address={location.address}
                  telephone={location.telephone}
                  link={location.link}
                  icon={{
                    url: Pin,
                    anchor: new window.google.maps.Point(32, 32),
                    scaledSize: new window.google.maps.Size(64, 64)
                  }}
                  key={`${location.lat}${location.lng}`}
                  onClick={this.onMarkerClick}
                  position={{ lat: location.lat, lng: location.lng }}
                />
              );
            })}

          <InfoWindow
            marker={activeMarker}
            onClose={this.onInfoWindowClose}
            visible={showingInfoWindow}
          >
            <MapBubble {...selectedPlace} />
          </InfoWindow>
        </Map>
      </MapContainer>
    );
  }
}
GoogleMap.propTypes = {
  mappedLocations: PropTypes.array
};

export default GoogleMap;
