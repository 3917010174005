import React from "react";
import PropTypes from "prop-types";
import Heading from "../../presentational_components/Heading";
import SubText from "../../presentational_components/SubText";
import Button from "../../presentational_components/Button";
import Confirm from "../../presentational_components/Confirm";
import styled from "styled-components";

const SubHeading = styled.h4`
  color: ${props => props.theme.white};
  font-size: 1.8rem;
  margin-bottom: 5px;
  font-weight: 500;
`;

class Result extends React.Component {
  render() {
    const {
      heading,
      sections
    } = this.props;
    const { outreach_path, outreach_order_path } = Routes;
    return (
      <>
        {heading && <Heading>{heading}</Heading>}

        {sections.map(section => <>
          <SubHeading>{section.heading}</SubHeading>
          <SubText>{section.body}</SubText>
        </>)}

        <SubText centered>
          <Button href={outreach_order_path()} data-method="delete">Register another kit</Button>
        </SubText>

        <SubText centered>
          <Confirm
                href={outreach_path()}
                data-method="delete"
                confirmText="Yes, end my session"
                cancelText="No, go back to the current form"
                heading="Are you sure?"
                body="If you continue you will have to start a new session to keep registering rapid self-test kits."
              >End the session</Confirm>
        </SubText>
      </>
    );
  }
}

Result.propTypes = {
  heading: PropTypes.string.isRequired,
  orderSummary: PropTypes.string.isRequired,
  weWillTextYouWhen: PropTypes.array,
  supportText: PropTypes.string.isRequired,
  supportHeading: PropTypes.string.isRequired
};

export default Result;
