import React from "react";
import PropTypes from "prop-types";

import AbstractQuestion from "../question_components/AbstractQuestion";
import SelectBox from "../../presentational_components/SelectBox";

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelected: props.value ? true : false,
      defaultValue: props.value ? props.value : null
    };
  }

  handleChange = () => {
    this.setState({
      valueSelected: true
    });
  };

  render() {
    const { options, placeholder, formName, attributeName, isSearchable = false } = this.props;
    const { valueSelected, defaultValue } = this.state;
    const optionsOrGroupedOptions = Array.isArray(options) && options || Object.keys(options).map(optionKey => { return { label: optionKey, options: options[optionKey] }; });

    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={valueSelected}
      >
        <SelectBox
          isSearchable={isSearchable}
          onChange={selectedOption => this.handleChange(selectedOption)}
          classNamePrefix={"react-select"}
          options={optionsOrGroupedOptions}
          placeholder={placeholder}
          name={`${formName}[${attributeName}]`}
          id={`${formName}_${attributeName}`}
          defaultValue={defaultValue}
          aria-label={placeholder}
        ></SelectBox>
      </AbstractQuestion>
    );
  }
}

Select.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.object
};

export default Select;
