class Tabs {
  constructor() {
    const tabs = document.querySelectorAll("[data-tabid]");
    const hashId = window.location.hash.split("--")[0].replace("#", "");
    this.tabIds = this.getTabIds();
    if (!tabs.length) return;

    const tabToLoad = this.tabIds.includes(hashId)
      ? document.querySelector(`[data-tabid="${hashId}"]`)
      : tabs[0];

    this.showTab.call(tabToLoad);
    this.bindClickHandlers();
    this.bindHashChangeHandler();
  }

  getTabIds() {
    let tabIdsArray = [];
    document.querySelectorAll("[data-tabid]").forEach(el => {
      tabIdsArray.push(el.dataset.tabid);
    });
    return tabIdsArray;
  }

  bindHashChangeHandler() {
    window.addEventListener("hashchange", () => {
      this.handleHashChange();
    });
  }

  bindClickHandlers() {
    document.querySelectorAll("[data-tabid]").forEach(el =>
      el.addEventListener("click", () => {
        window.location.hash = el.dataset.tabid;
      })
    );
  }

  handleHashChange() {
    const hash = window.location.hash.includes("--")
      ? window.location.hash.split("--")[0].replace("#", "")
      : window.location.hash.replace("#", "");

    const hashMatchesAccordionId = this.tabIds.includes(hash);
    const tabToLoad = document.querySelector(`[data-tabid="${hash}"]`);
    hashMatchesAccordionId && this.showTab.call(tabToLoad);
  }

  showTab() {
    const tabId = this.dataset.tabid;
    const tabToShow = document.getElementById(tabId);
    const tabsOffsetTop = document.querySelector(".tabs").offsetTop;
    const showContentWithinTab = window.location.hash.includes("--");
    document
      .querySelectorAll(".tabs__tab")
      .forEach(el => el.classList.remove("tabs__tab--active"));

    document
      .querySelectorAll(".tabbed-content__wrapper")
      .forEach(el => el.classList.remove("tabbed-content__wrapper--visible"));

    tabToShow.classList.add("tabbed-content__wrapper--visible");
    this.classList.add("tabs__tab--active");

    if (window.location.hash === "#faqs") {
      const faqsOffsetTop = document.querySelector("[name='faqs']").offsetTop;
      window.scrollTo(0, faqsOffsetTop);
      return;
    } else if (showContentWithinTab) {
      const anchoredContentOffsetTop = document.querySelector(
        `[name='${window.location.hash.replace("#", "")}']`
      ).offsetTop;
      window.setTimeout(() => {
        window.scrollTo(0, anchoredContentOffsetTop);
      }, 300);
    } else if (window.innerWidth > 767 && window.scrollY > tabsOffsetTop) {
      window.scrollTo(0, tabsOffsetTop);
    }
  }
}

document.addEventListener("turbolinks:load", () => {
  new Tabs();
});
