import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AbstractQuestion from "../question_components/AbstractQuestion";
import SubText from "../../presentational_components/SubText";
import LinkText from "../../presentational_components/LinkText";
import Checkbox from "../../presentational_components/Checkbox";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:15px
`;

const Message = styled(SubText)`
  margin-bottom: 0;
`;

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selected: props.value === "on" ? true : false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({ selected: !this.state.selected });
  }

  render() {
    const { formName, attributeName } = this.props;
    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={this.state.selected}
      >
        <Wrapper>
          <SubText>
            You must use your own mobile phone number. Do not use your number to order for friends or family &ndash; these orders will be refused.<br/>
            <br/>
            Before you begin, please confirm:
            <ul>
              <li>I am over 16</li>
              <li>I have a UK mobile phone number</li>
              <li>I agree to the {" "}
                <LinkText
                  href={Routes.static_pages_terms_path()}
                  target={"_blank"}
                >
                  terms and conditions
                </LinkText></li>
              <li>I am ordering a kit for myself</li>
              <li>I am providing my own mobile number and email address</li>
            </ul>

            To ensure clinical safety and confidentiality, <strong>you cannot order for someone else</strong>.
          </SubText>
        </Wrapper>
        <Wrapper>
          <Checkbox
            name={`${formName}[${attributeName}]`}
            id={`${formName}_${attributeName}`}
            checked={this.state.selected}
            onChange={this.handleChange}
          />
          <Message>
            <label htmlFor={`${formName}_${attributeName}`}>
              I understand and agree, and confirm that <strong>I am ordering for myself</strong> and <strong>providing my own mobile number and email address</strong>.
            </label>
          </Message>
        </Wrapper>
      </AbstractQuestion>
    );
  }
}

TermsAndConditions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  value: PropTypes.string,
  formName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired
};

export default TermsAndConditions;
