import React from "react";
import PropTypes from "prop-types";

import Heading from "../presentational_components/Heading";
import SubText from "../presentational_components/SubText";
import ErrorFeedback from "../presentational_components/ErrorFeedback";

import Option from "./Option";

function Progress1({ formData, setFormDataItem, error = false }) {

  const formattedMobileNumber = () => formData.mobile_number.value.replace(/^44(\d{4})(\d{3})(\d+)$/, '0$1 $2 $3');

  gtag("event", "result_page_loaded", {
    event_category: "result_journey",
    event_label: "result_reporting_page"
  });

  return(
    <>
      <Heading>What is your result?</Heading>
      <SubText>For the kit registered to<br />{formattedMobileNumber()}</SubText>

      <Option value="reactive" formData={formData} setFormDataItem={setFormDataItem} />
      <Option value="negative" formData={formData} setFormDataItem={setFormDataItem} />
      <Option value="failed" formData={formData} setFormDataItem={setFormDataItem} />


      {error && <ErrorFeedback message="We couldn't record your result. Please try again." />}
    </>
  )
}

Progress1.propTypes = {
  formData: PropTypes.object.isRequired
};

export default Progress1;
