import Chevron from "../svg/chevron_right.svg";
import styled from "styled-components";

const GreenChevron = styled(Chevron)`
  width: 16px;
  stroke: ${props => props.theme.salmon};
  * {
    stroke: ${props => props.theme.green};
  }
`;

export default GreenChevron;
