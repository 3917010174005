import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AbstractQuestion from "../question_components/AbstractQuestion";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";
import StyledInput from "../../presentational_components/StyledInput";


class SingleTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value ? props.value : "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { characterLimit } = this.props;
    if (characterLimit && event.target.value.length > characterLimit) {
      return;
    }
    this.setState({ value: event.target.value });
  }

  render() {
    const {
      formName,
      label,
      error,
      attributeName,
      isLoading,
      inputType,
      disabled,
      required,
    } = this.props;

    const { value } = this.state;
    const canProgress = value.length || !required;
    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={!!canProgress}
      >
        <StyledInput
          inputType={inputType}
          disabled={disabled}
          loading={attributeName === "postcode" && isLoading}
          value={this.state.value}
          onChange={this.handleChange}
          label={label}
          formName={formName}
          attributeName={attributeName}
          invalid={!!error}
          autoFocus={true}
        />

        {error && <ErrorFeedback message={error} />}
      </AbstractQuestion>
    );
  }
}

SingleTextInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  attributeName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  characterLimit: PropTypes.number,
  required: PropTypes.bool
};

SingleTextInput.defaultProps = {
  required: true
};

export default SingleTextInput;
