import styled from "styled-components";

const SubHeading = styled.h3`
  color: ${props => props.theme.offWhite};
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: 700;
`;

export default SubHeading;
