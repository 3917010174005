import styled from "styled-components";
import Spinner from "images/icons_journey/loading_spinner_purple.svg";

const SearchBox = styled.input.attrs({ type: "text" })`
  border: 1px solid ${props => props.theme.purple};
  height: 40px;
  transition: background-color 0.2s;
  width: 100%;
  max-width: 350px;
  border-radius: 3px;
  font-size: 1.6rem;
  padding-left: 10px;
  color: ${props => props.theme.grey};
  text-transform: uppercase;
  margin-right: 10px;

  ${({ isLoading }) =>
    isLoading &&
    `background-image: url(${Spinner});
    background-repeat: no-repeat;
    background-position: center right;
  `}

  &::placeholder {
    opacity: 0.5;
    font-weight: 300;
    text-transform: none;
  }
`;

export default SearchBox;
