import React from "react";
import styled from "styled-components";
import { Oval } from "svg-loaders-react";

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  flex-grow: 1;
`;

export default function Loading() {
  return (
    <LoadingWrapper>
      <Oval />
    </LoadingWrapper>
  );
}
