import styled from 'styled-components';

const VerticallyCentered = styled.div`
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  position: absolute;
  padding: inherit;
`;

export default VerticallyCentered;
