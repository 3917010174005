import styled from "styled-components";
import PropTypes from "prop-types";

const colours = ({ colour, theme }) => {
  switch(colour) {
    case 'purple':
      return {
        text: theme.white,
        background: theme.purple,
        backgroundHover: theme.purpleLight
      };
    case 'green':
      return {
        text: theme.white,
        background: theme.green,
        backgroundHover: theme.greenDark
      };
  }
};

const Button = styled.a`
  color: ${props => colours(props).text};
  background-color: ${props => colours(props).background};
  transition: background-color 0.2s;
  text-decoration: none;
  line-height: 4.4rem;
  font-size: 1.6rem;
  max-width: 240px;
  text-align: center;
  display: inline-block;
  width: 100%;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => colours(props).backgroundHover};
  }
`;

Button.propTypes = {
  colour: PropTypes.string.isRequired
};

Button.defaultProps = {
  colour: 'green'
};


export default Button;
