import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { OutreachContext } from '../lib/OutreachContext';

import Heading from '../../presentational_components/Heading';
import SubText from '../../presentational_components/SubText';
import InputContainer from '../../presentational_components/InputContainer';
import reactSelectStyles from '../../../reactSelectStyles'

function Region({ regions, onKeyDown }) {
  const { state, dispatch } = React.useContext(OutreachContext);

  const handleChange = ({ value }) => {
    dispatch({ type: 'setUserData', key: 'outreach_region', value });
  }

  useEffect(() => {
    dispatch({ type: 'setFormValid', value: state.userData.outreach_region.length >= 3 });
  }, [state.userData.outreach_region]);

  const options = regions.map(region => ({ value: region, label: region }));

  return (
    <>
      <Heading>Which region are you working in today?</Heading>
      <SubText>This helps us keep track of where and how rapid self-test kits are being given out.</SubText>
      <SubText>This is a list of the trusts that have commissioned rapid self-test kits. If you can't find your region use the most accurate one. For example, 'East hertfordshire' would be 'Hertforshire".</SubText>

      <InputContainer>

        <Select
          options={options}
          value={options.filter(opt => opt.value == state.userData.outreach_region)}
          styles={reactSelectStyles}
          placeholder='Select or start typing...'
          onChange={handleChange}
          autoFocus={true}
          onKeyDown={onKeyDown}
        />
      </InputContainer>
    </>
  );
};

Region.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Region;
