import React from 'react';
import { OutreachContext } from '../lib/OutreachContext';

import Heading from '../../presentational_components/Heading';
import SubHeading from '../../presentational_components/SubHeading';
import SubText from '../../presentational_components/SubText';
import List from '../../presentational_components/List';
import ListItem from '../../presentational_components/ListItem';
import LinkText from "../../presentational_components/LinkText";
import Button from '../../presentational_components/Button'

function Intro() {
  const { dispatch } = React.useContext(OutreachContext);

  const handleSubmit = () => {
    dispatch({ type: 'incrementProgress' });
  };

  return(
    <>
      <Heading>Register rapid self-test kits</Heading>
      <SubText>This portal is for outreach workers or volunteers working in England to register freetesting.hiv rapid self-test kits to service users.</SubText>

      <SubText>You will need to start a session, firstly by entering your name, email address, organisation and the region you're working in. Then you can register as many kits as you need to.</SubText>

      <SubText>End the session when you're finished to clear the information for another person to use this device. The session will automatically time out after 12 hours.</SubText>

      <SubText>You can start an order again or end your session at any time. For more information, <LinkText href={"https://www.youtube.com/watch?v=uFbb89R-YA0"} target={"_blank"}>watch this video</LinkText>.</SubText>

      <SubHeading>Required service user information</SubHeading>

      <SubText>Required information:</SubText>

      <List>
        <ListItem>UK mobile phone number</ListItem>
        <ListItem>Name</ListItem>
        <ListItem>Date of birth</ListItem>
        <ListItem>Email address and/or postal address</ListItem>
      </List>

      <SubText>If you have trouble with the internet connection, note the required information down to fill in later when you're able to connect to the internet. If this is the case make sure you tick the checkbox 'Mobile number unverified' when asked.</SubText>

      <SubText centered>
        <Button onClick={handleSubmit}>Start a session</Button>
      </SubText>
    </>
  )
}

export default Intro;
