const nextPath = outreach => outreach ? Routes.outreach_order_next_path() : Routes.journey_next_path();
const previousPath = outreach => outreach ? Routes.outreach_order_previous_path() : Routes.journey_previous_path();

const callApi = function ({
  isNextCall = true,
  csrfToken,
  onSuccess,
  onFailure,
  outreach = false
}) {
  const method = isNextCall ? "POST" : "GET";
  const formData = isNextCall
    ? new FormData(document.getElementById("order-journey-form"))
    : null;

  const formDataFieldsLength = formData
    ? Array.from(formData).length
    : [];
  const data = formDataFieldsLength > 0
    ? formData
    : null;

  const endpoint = isNextCall
    ? nextPath(outreach)
    : previousPath(outreach);

  return fetch(endpoint, {
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": csrfToken
    },
    method,
    body: data
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      onSuccess(data);
    })
    .catch(() => {
      onFailure();
    });
};

export default callApi;
