import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import theme from "../theme";

const InfoWindowContentWrapper = styled.div``;
const LocationHeading = styled.h2`
  color: ${props => props.theme.purple};
  font-family: ${props => props.theme.fontFamilyPrimary};
  font-size: 1.9rem;
  margin-bottom: 10px;
`;

const LocationInfo = styled.span`
  display: block;
  color: ${props => props.theme.grey};
  font-size: 1.6rem;
  margin-bottom: 0;
  text-decoration: none;
`;

const Address = styled.p`
  color: ${props => props.theme.green};
  font-size: 1.6rem;
  width: 80%;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

function MapBubble({ ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <InfoWindowContentWrapper>
        <LocationHeading>{props.name}</LocationHeading>
        <LocationInfo>
          {props.telephone && (
            <Link href={`tel:${props.telephone}`}>{props.telephone}</Link>
          )}
        </LocationInfo>

        {props.address && (
          <Address>
            <Link href={props.link}>{props.address}</Link>
          </Address>
        )}
      </InfoWindowContentWrapper>
    </ThemeProvider>
  );
}

MapBubble.propTypes = {
  name: PropTypes.string,
  telephone: PropTypes.string,
  link: PropTypes.string,
  address: PropTypes.string
};

export default MapBubble;
