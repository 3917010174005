import Select from "react-select";
import styled from "styled-components";
import TickGrey from "images/icons_journey/tick_grey.svg";

const SelectBox = styled(Select)`
  font-size: 1.6rem;
  border-color: ${props => props.theme.offWhite};
  background-color: transparent;
  border-radius: 0;
  color: ${props => props.theme.offWhite};
  margin-bottom: 20px;
  flex-basis: 30%;
  margin-right: 5px;

  ${({ type }) => type === "month" && `flex-basis: 40%;`}

  @media screen and (min-width: ${props => props.theme.breakpointMedium}) {
    margin-right: 20px;
    max-width: 360px;
    margin-bottom: 0;
  }

  &:focus {
    color: ${props => props.theme.offWhite};
  }

  .react-select {
    &__placeholder {
      color: ${props => props.theme.white};
      font-weight: 300;
    }

    &__control {
      border-radius: 0;
      border-color: ${props => props.theme.offWhite};
      background-color: transparent;
      font-family: ${props => props.theme.fontFamilyPrimary};
    }

    &__indicator {
      width: 25px;
    }

    &__value-container {
      padding-left: 3px;
      &--has-value {
        color: ${props => props.theme.white};
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 0;
    }

    &__single-value {
      font-weight: 300;
      color: ${props => props.theme.white};
    }

    &__menu {
      font-family: ${props => props.theme.fontFamilyPrimary};
      border-radius: 0;
    }

    &__group-heading {
      font-weight: 500;
      color: ${props => props.theme.grey};
    }

    &__option {
      font-family: ${props => props.theme.fontFamilyPrimary};
      color: ${props => props.theme.grey};
      font-weight: 300;
      &--is-selected {
        font-weight: 500;
        background-image: url(${TickGrey});
        background-color: rgba(242, 160, 153, 0.2);
        background-position: center right 10px;
        background-repeat: no-repeat;
      }

      &--is-focused {
        background-color: rgba(242, 160, 153, 0.2);
      }
    }

    &__input {
      color: ${props => props.theme.offWhite};
    }
  }

  input[type="submit"] {
    color: ${props => props.theme.offWhite};
    font-size: 1.6rem;
  }
`;

export default SelectBox;
