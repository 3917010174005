class Accordion {
  constructor() {
    const initialHash = window.location.hash.replace("#", "");
    this.accordionIds = this.getAccordionIds();
    const hashMatchesAccordionId = this.accordionIds.includes(initialHash);

    if (initialHash && hashMatchesAccordionId) {
      this.showAccordionContent(initialHash);
    }

    if (this.accordionIds.length) {
      this.bindClickHandlers();
      this.bindHashChangeHandler();
    }
  }

  bindHashChangeHandler() {
    window.addEventListener("hashchange", () => {
      this.handleHashChange();
    });
  }

  bindClickHandlers() {
    document
      .querySelectorAll("[data-accordionid]")
      .forEach(el => el.addEventListener("click", this.toggleVisibility));
  }

  getAccordionIds() {
    let accordionIdsArray = [];
    document.querySelectorAll("[data-accordionid]").forEach(el => {
      accordionIdsArray.push(el.dataset.accordionid);
    });
    return accordionIdsArray;
  }

  handleHashChange() {
    const hash = window.location.hash.replace("#", "");
    const hashMatchesAccordionId = this.accordionIds.includes(hash);

    hashMatchesAccordionId && this.showAccordionContent(hash);
  }

  showAccordionContent(id) {
    const answer = document.querySelector(`[data-accordioncontentid=${id}]`);
    const question = document.querySelector(`[data-accordionid=${id}]`);
    const accordionOffset = document.querySelector(`[name='${id}']`).offsetTop;
    question.classList.add("accordion__control--open");
    answer.classList.add("accordion__content--visible");
    window.scrollTo(0, accordionOffset);
  }

  toggleVisibility() {
    const answer = document.querySelector(
      `[data-accordioncontentid=${this.dataset.accordionid}]`
    );
    this.classList.toggle("accordion__control--open");
    answer.classList.toggle("accordion__content--visible");
  }
}

document.addEventListener("turbolinks:load", () => {
  new Accordion();
});
