export default {
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#2f2f81',
    border: '1px solid #fff',
    borderRadius: '0 0 3px 3px',
    borderTop: 'none',
    color: '#fff',
    display: state.options.length ? 'block' : 'none',
    fontSize: '1.5rem',
    fontWeight: '100',
    marginTop: '-5px',
  }),
  menuList: (provided) => ({
    ...provided,
    background: '#2f2f81',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#fff' : 'transparent',
    color: state.isFocused ? '#2f2f81' : '#fff',
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: '1px solid #fff',
    borderColor: 'inherit !important',
    borderRadius: state.options.length ? '3px 3px 0 0' : '3px',
    color: '#fff',
    fontFamily: '"IBM Plex Sans",sans-serif',
    fontSize: '1.8rem',
    fontWeight: '100',
    margin: '10px 0 5px 0',
    outlineOffset: state.isFocused ? '0px' : 'inherit',
    padding: '12px',
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    fontWeight: '100',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#ccc',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff'
  })
};
