import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OutreachContext } from './lib/OutreachContext';
import Intro from './pages/Intro';
import Name from './pages/Name';
import Email from './pages/Email';
import Organisation from './pages/Organisation';
import Region from './pages/Region';
import Navigation from '../presentational_components/Navigation';

function OutreachContainer({ csrfToken, regions }) {
  const { state, dispatch } = useContext(OutreachContext);

  const createSession = () => fetch('', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-type': 'application/json'
    },
    body: JSON.stringify(state.userData)
  }).then(data => data.json());

  const handleSubmit = ({ isNextCall }) => {
    if (isNextCall) {
      if (isLastStep()) {
        createSession().then(json => {
          if (json.success) {
            window.location.href = json.redirect;
          }
        });
      } else {
        dispatch({ type: 'incrementProgress' });
      }
    } else {
      dispatch({ type: 'decrementProgress' });
    }
  };

  const handleKeyDownSubmit = e => {
    if (e.keyCode == 13 && state.formValid) {
      handleSubmit({ isNextCall: true });
    }
  }

  const steps = [
    <Intro />,
    <Name onKeyDown={handleKeyDownSubmit} />,
    <Email onKeyDown={handleKeyDownSubmit} />,
    <Organisation onKeyDown={handleKeyDownSubmit} />,
    <Region regions={regions} onKeyDown={handleKeyDownSubmit} />
  ];

  const isFirstStep = () => state.progress == 0;
  const isLastStep = () => state.progress == steps.length - 1;

  return (
    <>
      {steps[state.progress]}

      {isFirstStep() || <Navigation
        prevBtnHidden={isFirstStep()}
        nextBtnDisabled={!state.formValid}
        handleSubmit={handleSubmit}
        isLoading={false}
        isLastStep={isLastStep()}
        completeLabelText='Start session'
      />}
    </>
  )
};

OutreachContainer.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.string).isRequired,
  csrfToken: PropTypes.string.isRequired
};

export default OutreachContainer;
