import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AlertIcon from "../svg/alert.svg";
const ErrorFeedbackWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    min-width: 7px;
    margin-top: 5px;
  }
`;

const ErrorFeedbackMessage = styled.p`
  padding-left: 10px;
  color: ${props => props.theme.red};
  font-size: 1.6rem;
  font-weight: 400;
`;

export default function ErrorFeedback({ message }) {
  return (
    <ErrorFeedbackWrapper>
      <AlertIcon />
      <ErrorFeedbackMessage>{message}</ErrorFeedbackMessage>
    </ErrorFeedbackWrapper>
  );
}

ErrorFeedback.propTypes = {
  message: PropTypes.string.isRequired
};
