import React from "react";
import PropTypes from "prop-types";

import AbstractQuestion from "../question_components/AbstractQuestion";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";
import StyledInput from "../../presentational_components/StyledInput";
import CheckboxWithLabel from "../../presentational_components/CheckboxWithLabel";
import SubText from "../../presentational_components/SubText";


class OutreachMobileNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        mobile_number: props.values && props.values.mobile_number ? props.values.mobile_number : "",
        mobile_number_user_not_present: Boolean(props.values && props.values.mobile_number_user_not_present)
      }
    };
    this.handleMobileNumberChange = this.handleMobileNumberChange.bind(this);
    this.handleUserNotPresentChange = this.handleUserNotPresentChange.bind(this);
  }

  handleMobileNumberChange(event) {
    const { characterLimit } = this.props;
    const { values } = this.state;

    if (characterLimit && event.target.value.length > characterLimit) {
      return;
    }
    this.setState({ values: { ...values, mobile_number: event.target.value } });
  }

  handleUserNotPresentChange(event) {
    const { values } = this.state;

    this.setState({ values: { ...values, mobile_number_user_not_present: event.target.checked } });
  }

  render() {
    const {
      formName,
      label,
      error,
      attributeName,
      inputType,
      disabled
    } = this.props;

    const { values: { mobile_number, mobile_number_user_not_present } } = this.state;
    const canProgress = mobile_number.length;
    return (
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={!!canProgress}
      >
        <StyledInput
          inputType={inputType}
          disabled={disabled}
          value={mobile_number}
          onChange={this.handleMobileNumberChange}
          label={label}
          formName={formName}
          attributeName={attributeName}
          invalid={!!error}
          autoFocus={true}
        />
        {error && <ErrorFeedback message={error} />}

        <SubText>
          <CheckboxWithLabel checked={mobile_number_user_not_present} onChange={this.handleUserNotPresentChange} name={`${formName}[mobile_number_user_not_present]`} id={`${formName}_mobile_number_user_not_present`}>
            <strong>Service user not present</strong><br />
            Tick this box if the service user isn’t with you now - the mobile verification code text message won’t be sent to them.
          </CheckboxWithLabel>
        </SubText>
      </AbstractQuestion>
    );
  }
}

OutreachMobileNumber.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  clarificationText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  attributeName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  characterLimit: PropTypes.number
};

export default OutreachMobileNumber;
